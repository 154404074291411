.multipleOnly, .singleOnly, .singleOnlyFlex, .multipleOnlyFlex {
	display: none;
}
.clean-navbar.fixed-top + .page {
	padding-top: 0;
}
.clean-navbar {
	min-height: 4.625rem;
}
.img-logo {
	max-width: 12em;
}
@media (max-width: 576px) {
	.img-logo {
		max-width: 10em;
	}
}
@media (min-width: 576px) {
	.modal-xxl, .modal-full {
		max-width: 100%;
		margin: 1.75rem 2rem;
	}
}
@media (min-width: 1200px) {
	.modal-xxl {
		max-width: 84%;
		margin: 1.75rem 8%;
	}
}
.login-page .clean-hero, .landing-page .clean-hero {
	min-height: calc(100vh - 4.625rem);
	padding-top: 9vh;
	padding-bottom: 9vh;
	background:url('../img/bg-laptop-portrait.jpg') no-repeat center center;
	background-position: scroll;
	background-size:cover;
	color:rgba(9, 162, 255, 0.85);
}
@media all and (orientation: landscape) {
	.login-page .clean-hero, .landing-page .clean-hero {
		background:url('../img/bg-laptop-landscape.jpg') no-repeat center center;
	}
}
.landing-page .clean-hero .card {
	color: #373840;
	text-align: left;
}
.shadow-xl {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.6) !important;
}
.bg-img-contain {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
.clean-hero div {
	z-index: 1;
}
.centeredBox {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 96%;
	display: flex;
}
.centeredAll {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	width: 96%;
	display: flex;
}
.bg-login-image {
	background-image: url('../img/undraw_access_account_re_8spm.svg');
}

.text-large {
	font-size: 2em !important;
}
.btn-block {
	width: 100% !important;
}
.home-links {
	text-decoration: none !important;
    display:flex;
    justify-content: center;
    align-items: center;
	width: 18em;
	height: 18em;
	border-radius: 50% !important;
}
.home-links:hover {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.6) !important;
}
@media (max-width: 768px) {
	.home-links {
		width: 100%;
		height: 100%;
		min-height: 16em;
		border-radius: 25% !important;
	}
	.home-links:hover {
		box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.6) !important;
	}
}
footer, header, .shadow-line {
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
}
.loading {
	padding: 6rem;
}
.required-input {
	color: red;
}
.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}
.float-none {
	float: none !important;
}
.modal-on-top {
	z-index: 1060;
}
.bg-gradient-primary{background-color:#089fe3;background-image:linear-gradient(180deg,#089fe3 10%,#36b9cc);background-size:cover;}
.bg-gradient-secondary{background-color:#858796;background-image:linear-gradient(180deg,#858796 10%,#60616f);background-size:cover;}
.bg-gradient-success{background-color:#1cc88a;background-image:linear-gradient(180deg,#1cc88a 10%,#13855c);background-size:cover;}
.bg-gradient-info{background-color:#36b9cc;background-image:linear-gradient(180deg,#36b9cc 10%,#258391);background-size:cover;}
.bg-gradient-warning{background-color:#f6c23e;background-image:linear-gradient(180deg,#f6c23e 10%,#dda20a);background-size:cover;}
.bg-gradient-danger{background-color:#e74a3b;background-image:linear-gradient(180deg,#e74a3b 10%,#be2617);background-size:cover;}
.bg-gradient-light{background-color:#f8f9fc;background-image:linear-gradient(180deg,#f8f9fc 10%,#c2cbe5);background-size:cover;}
.bg-gradient-dark{background-color:#5a5c69;background-image:linear-gradient(180deg,#5a5c69 10%,#373840);background-size:cover;}
.bg-gradient-primary-to-secondary {
	background-color: #089fe3 !important;
	background-image: linear-gradient(135deg, #089fe3 0%, rgba(183, 12, 127, 0.8) 100%) !important;
}
.bg-gradient-primary-to-info {
	background-color: #089fe3 !important;
	background-image: linear-gradient(135deg, #089fe3 0%, rgba(37, 131, 145, 0.8) 100%) !important;
}
.bg-img-contain {
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}
a.go-up-fixed {
	display: none;
	position: fixed;
	bottom: 0px;
	right: 0px;
	border:none;
	border-radius:50%;
	margin:4px;
	color:#fff;
	text-align:center;
	background-color:#33b7cc;
	opacity:0.6;
	z-index: 900;
	width: 3rem;
	height: 3rem;
	line-height: 3.5rem;
}
a.go-up-fixed:hover {
	opacity:1;
}
*:disabled {
	cursor: not-allowed;
}
.card-header {
	font-size: 1.6em;
}
.clean-block.clean-hero p {
	font-size: 1.2em;
	margin-bottom: 1rem;
}
.clean-block.clean-hero {
	text-align: inherit;
}
.btn-icon {
	padding: 0;
	justify-content: center;
	overflow: hidden;
	border-radius: 100%;
	flex-shrink: 0;
	height: calc((0.875rem * 1) + (0.875rem * 2) + (2px)) !important;
	width: calc((0.875rem * 1) + (0.875rem * 2) + (2px)) !important;
}
.btn-icon .feather {
	margin-top: 0 !important;
}
.feather-xl {
	height: 2.5rem !important;
	width: 2.5rem !important;
}
.btn-icon.btn-xl {
	height: calc((1.125rem * 1) + (1.25rem * 2) + (2px)) !important;
	width: calc((1.125rem * 1) + (1.25rem * 2) + (2px)) !important;
	border-radius: 100%;
}
.btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
	height: calc((1rem * 1) + (1.125rem * 2) + (2px)) !important;
	width: calc((1rem * 1) + (1.125rem * 2) + (2px)) !important;
}
.btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
	height: calc((0.75rem * 1) + (0.5rem * 2) + (2px)) !important;
	width: calc((0.75rem * 1) + (0.5rem * 2) + (2px)) !important;
}
.btn-icon.btn-xs {
	height: calc((0.7rem * 1) + (0.25rem * 2) + (2px)) !important;
	width: calc((0.7rem * 1) + (0.25rem * 2) + (2px)) !important;
	border-radius: 100%;
}
.btn-icon.btn-link {
	text-decoration: none;
}
.dropdown-user .dropdown-menu .dropdown-header .dropdown-user-img {
	line-height: 3.2rem;
	height: 3rem;
	width: 3rem;
	margin-right: 0.6rem;
	border-radius: 100%;
}
nav .btn .badge {
	position: relative;
	top: -6px;
	right: 6px;
}
nav .badge {
	border-radius: 50%;
}
@media (min-width: 576px) {
	.dropdown-notifications .dropdown-menu .dropdown-notifications-item .dropdown-notifications-item-content .dropdown-notifications-item-content-text {
		max-width: 16rem;
	}
}
.text-table-sm {
	font-size: 0.8rem !important;
}
.thumb9r{
	max-width: 9rem;
}
.thumb12r{
	max-width: 12rem;
}
.dt-buttons button {
	padding: 0.5rem 0.75rem;
	font-size: 0.75rem;
	border-radius: 0.25rem;
}
.dataTables_wrapper {
	--bs-gutter-x: 0;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.sl-wrapper .sl-close {
	height: 4rem;
	width: 4rem;
	line-height: 4rem;
	font-size: 4.5rem;
}